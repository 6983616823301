import { useEffect, useState } from 'react';

import { getNbCardVisible } from '~/src/screens/Home/CatalogSwiper/utils';

const useScrollable = (nbItems: number) => {
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const nbCardVisible = getNbCardVisible();

    setIsScrollable(nbItems > nbCardVisible);
  }, [nbItems, setIsScrollable]);

  return isScrollable;
};

export default useScrollable;
