import { DESKTOP_ITEM_GAP } from '~/src/screens/Home/CatalogSwiper/layout';
import useScrollAnalytics from '~/src/screens/Home/CatalogSwiper/use-scroll-analytics';
import useScrollPosition from '~/src/screens/Home/CatalogSwiper/use-scroll-position';
import useScrollable from '~/src/screens/Home/CatalogSwiper/use-scrollable';
import { getNbCardVisible } from '~/src/screens/Home/CatalogSwiper/utils';

const useSwiperScroll = ({
  nbItems,
  mainContainer,
  scrollContainer,
  subcategoryName,
}: {
  nbItems: number;
  subcategoryName: string;
  mainContainer: HTMLDivElement | null;
  scrollContainer: HTMLDivElement | null;
}) => {
  useScrollAnalytics(scrollContainer, subcategoryName);

  const isScrollable = useScrollable(nbItems);

  const handleScroll = (direction: 'left' | 'right') => {
    if (scrollContainer && mainContainer) {
      const visibleContentWidth = mainContainer.getBoundingClientRect().width || 0;

      const nbCardVisible = getNbCardVisible();
      const cardWidth = (visibleContentWidth - nbCardVisible * DESKTOP_ITEM_GAP) / nbCardVisible;

      const itemWidth = cardWidth + DESKTOP_ITEM_GAP;

      const currentPosition = scrollContainer.scrollLeft;
      const hasScrolledAtLeastOneItem = currentPosition > itemWidth;

      const nbCardsScrolled = hasScrolledAtLeastOneItem ? currentPosition / itemWidth : 0;

      // Le "scroll gap" est le décalage à gérer pour aligner les "items" sur les bords
      const scrollGap = hasScrolledAtLeastOneItem
        ? (nbCardsScrolled - Math.floor(nbCardsScrolled)) * itemWidth
        : currentPosition;

      const leftDirectionScroll =
        scrollGap > 0
          ? // Décalage vers la gauche du nombre de cartes visibles avec les espacements
            // On réduit le scroll du décalage précédemment calculé pour afficher pleinement la carte qui ne l'était que partiellement affiché
            currentPosition - (nbCardVisible - 1) * itemWidth - scrollGap
          : currentPosition - visibleContentWidth;

      const rightDirectionScroll =
        scrollGap > 0
          ? // Décalage vers la droite du nombre de cartes visibles avec les espacements
            // La différence avec le décalage à gauche c'est qu'on doit afficher "l'autre partie de la carte" qui n'est que partiellement affiché
            currentPosition + (nbCardVisible - 1) * itemWidth + (itemWidth - scrollGap)
          : currentPosition + visibleContentWidth;

      scrollContainer.scrollTo({
        left: direction === 'left' ? leftDirectionScroll : rightDirectionScroll,
        behavior: 'smooth',
      });
    }
  };

  const { hasScrolled, hasFullyScrolled } = useScrollPosition(scrollContainer);

  return {
    isScrollable,
    handleScroll,
    hasScrolled,
    hasFullyScrolled,
  };
};

export default useSwiperScroll;
