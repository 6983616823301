import styled from 'styled-components';

import { H3styles } from '~/src/common/components/Typography';
import { media } from '~/src/styles-constants';

export const HomeQuickButtonsSection = styled.div`
  display: flex;
  flex: 1;

  margin-top: 16px;

  ${media.XL`
    background-color: ${({ theme }) => theme.palette.common.XLIGHT_GREEN};
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  `}
`;

export const LabelsContainer = styled.div`
  flex: 2;
  display: none;
  flex-direction: column;
  padding-right: 16px;
  ${media.XL`
    display: flex;
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;

  gap: 16px;

  ${media.XL`
    max-width: 435px;
    gap: 40px;
  `}
`;

export const QuickButtonContainer = styled.button`
  flex: 1;
  padding: 16px 8px;

  background-color: ${({ theme }) => theme.palette.common.XLIGHT_GREEN};
  border: 1px solid ${({ theme }) => theme.palette.common.LIGHT_GREEN};
  border-radius: 8px;

  ${media.XL`
    max-width: 197.5px;
    background-color: ${({ theme }) => theme.palette.common.WHITE};
    border: 1px solid ${({ theme }) => theme.palette.common.LIGHT_GREEN};
  `}
`;

export const QuickButtonText = styled.p`
  margin-top: 8px;

  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.common.PRIMARY};
  font-weight: 500;

  ${media.XL`
    white-space: nowrap;
  `}
`;

export const Username = styled.p`
  flex: 1;
  margin-bottom: 8px;

  > span {
    ${H3styles}
  }
`;
