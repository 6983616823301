import { useRouter } from 'next/router';

import { isTruthy } from '~/src/common/utils/guards';
import { useHome } from '~/src/queries/api-ecom/generated/api-ecom';
import { useGetHeadersQuery } from '~/src/queries/cms/generated/cms';
import { CATALOG_QUERY_PARAMS } from '~/src/screens/Home/queries';
import { transformHomeHeader } from '~/src/screens/Home/utils';

const useMarketingHeaders = () => {
  const { pathname } = useRouter();

  const { data: homeData } = useHome(CATALOG_QUERY_PARAMS, {
    query: { enabled: pathname === '/' },
  });

  const { data: homeHeaders } = useGetHeadersQuery(
    { headers_list: homeData?.headersIds },
    {
      enabled: pathname === '/' && (homeData?.headersIds?.length || 0) > 0,
      staleTime: Infinity,
    },
  );

  const sortedHeaders = (homeData?.headersIds || [])
    .map(id => homeHeaders?.headers?.find(p => p?.keplr_id === id))
    .filter(isTruthy);

  const marketingHeaders = sortedHeaders.filter(p => p.CTA_action).map(transformHomeHeader);

  return marketingHeaders;
};

export default useMarketingHeaders;
