import { SyntheticEvent } from 'react';
import type { UrlObject } from 'url';

import Link from '~/src/common/components/Link';
import Tracker from '~/src/common/services/Tracker';
import { ImageParams } from '~/src/common/typings/image';
import { getImageUrl } from '~/src/common/utils/cloudinary';
import constants from '~/src/constants';
import { ParsedMarketingHeader } from '~/src/screens/App/Header/types';

import * as S from './layout';

interface BaseComponentProps {
  title?: string | null;
  imageUrl: string;
  onClick: (e: SyntheticEvent) => void;
}

type LinkComponentProps = BaseComponentProps & {
  url: string;
};

type ButtonComponentProps = BaseComponentProps & {
  infoSlug: string;
};

interface Props {
  header: ParsedMarketingHeader;
  onMarketingModalOpen: (id: string) => void;
}

const HeaderCardButtonComponent = ({
  title,
  imageUrl,
  onClick,
  infoSlug,
}: ButtonComponentProps) => {
  return (
    <S.ButtonCardContainer>
      <Link href={`/info/${infoSlug}`} onClick={onClick} title={title || undefined}>
        <S.Image src={imageUrl} />
      </Link>
    </S.ButtonCardContainer>
  );
};

/**
 * Takes any URL, and :
 * - if it's an external URL keep it as-is
 * - if it's an internal URL transform it so it can work properly with the next router
 */
export const messageUrl = (url: string): { href: string | UrlObject; as?: string | UrlObject } => {
  if (!url.startsWith(constants.SITE_URL)) {
    return { href: url };
  }

  const { pathname } = new URL(url);
  const productMatch = pathname.match(/\/produit\/(.+)/);

  if (productMatch) {
    const slug = productMatch[1];

    return {
      href: { pathname: '/', query: { slug, fromList: 'header marketing' } },
      as: `/produit/${slug}`,
    };
  }

  return { href: pathname };
};

const HeaderCardLinkComponent = ({ title, imageUrl, url, onClick }: LinkComponentProps) => {
  const { href } = messageUrl(url);

  return (
    <S.LinkCard href={href} shallow scroll={false} onClick={onClick}>
      <img src={imageUrl} alt={title || undefined} />
    </S.LinkCard>
  );
};

const HeaderCard = ({ header, onMarketingModalOpen }: Props) => {
  const { image, alt_text: altText, CTA_action: ctaAction, keplr_name: name } = header;

  const iotUrl = getImageUrl(image.url, ImageParams.headerCard);
  const infoSlug = ctaAction?.modale_marketing?.info_slug;

  const sendClickEvent = () => {
    Tracker.sendEvent('header marketing click', {
      'content name': name,
      'link type': infoSlug ? 'marketing modal' : 'URL',
      'link': (infoSlug ? ctaAction.modale_marketing?.keplr_name : ctaAction?.URL_link) || '',
    });
  };

  if (infoSlug != null) {
    return (
      <HeaderCardButtonComponent
        title={altText}
        imageUrl={iotUrl}
        infoSlug={infoSlug}
        onClick={e => {
          sendClickEvent();
          e.preventDefault();
          onMarketingModalOpen(infoSlug || '');
        }}
      />
    );
  }

  if (ctaAction?.URL_link) {
    return (
      <HeaderCardLinkComponent
        title={altText}
        imageUrl={iotUrl}
        url={ctaAction.URL_link}
        onClick={sendClickEvent}
      />
    );
  }

  return null;
};

export default HeaderCard;
