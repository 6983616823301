import { CardInfoWithoutPositionAndCountProperties } from '~/src/common/services/Tracker';
import { isProduct } from '~/src/typings/products/guards';
import { CatalogItem } from '~/src/typings/products/types';

const useItemAnalytics = (
  items: {
    product: CatalogItem | undefined;
    productId: string;
  }[],
  categoryAnalyticsProperties?: CardInfoWithoutPositionAndCountProperties,
) => {
  const numberOfProducts = items.filter(i => i.product && isProduct(i.product)).length;
  const numberOfCards = items.filter(i => !!i.product).length;

  return {
    'number of products in the list': numberOfProducts,
    'number of cards in the list': numberOfCards,
    ...categoryAnalyticsProperties,
  };
};
export default useItemAnalytics;
