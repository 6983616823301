import dayjs from '~/src/common/services/Date';
import colors from '~/src/common/themes/main/colors';
import { DeliverySlot } from '~/src/common/typings/cart';
import { sortBy } from '~/src/common/utils/array';
import { getTomorrowTimestamp } from '~/src/common/utils/date';

import type { AUTOMATIC_SLOT_INTERVAL } from './Main/Main';
import { TextWithColorEnum } from './types';

/**
 * Returns a sorted array of delivery slots whose start time is included in the time interval provided and who are not full
 * @param slots an array of delivery timeslot
 * @param from start time of the slot timestamp
 * @param to end time of the slot timestamp
 * @returns a sorted array of delivery slots
 */

export const getSlotFromStartHour = (
  slots: DeliverySlot[],
  [startHour, endHour]: typeof AUTOMATIC_SLOT_INTERVAL,
) => {
  const filteredSlots = slots.filter(({ from, isExpired, isFull }) => {
    const slotHour = dayjs(from).hour();

    return !isExpired && slotHour >= startHour && slotHour < endHour && !isFull;
  });

  return sortBy(filteredSlots, item => item.from);
};

/**
 * @param slots tableau de créneaux horaires
 * @param referenceDate date au plus tôt à partir de laquelle le prochain créneau disponible est recherché de préférence
 * @returns le créneau disponible le plus proche (s'il y en a un)
 */
const PREFERRED_HOUR = 16; // Heure de préférence pour la sélection de slot auto, par exemple le moment où il y a le plus de stocks
export const getNextAvailableSlot = <
  T extends { from: number; isFull: boolean; isExpired: boolean; isExcluded: boolean },
>(
  slots: T[],
  referenceDate = getTomorrowTimestamp(PREFERRED_HOUR),
): T | undefined => {
  const slotsSorted = sortBy(slots, item => item.from);
  const nextSlotsAfterReference = slotsSorted.filter(({ from }) => from >= referenceDate);
  const nextSlotsBeforeReference = slotsSorted.filter(({ from }) => from <= referenceDate);

  const selectedSlot = [...nextSlotsAfterReference, ...nextSlotsBeforeReference.reverse()].find(
    slot =>
      !slot.isExpired && !slot.isFull && !slot.isExcluded && dayjs(slot.from).isAfter(+dayjs()),
  );

  return selectedSlot;
};

// Mapping entre les couleurs définies dans strapi et les couleurs du projet
// Strapi ne permet pas de donner de valeurs aux enum, on ne peut mentionner que des clés
export const colorsEnumValues = {
  [TextWithColorEnum.Blanc]: colors.BACKGROUND_2,
  [TextWithColorEnum.Rose]: colors.PITAYA,
  [TextWithColorEnum.Sombre]: colors.BLACK,
  [TextWithColorEnum.Vert]: colors.PRIMARY,
};
