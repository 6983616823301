import { FunctionComponent, SVGProps } from 'react';

import * as S from './layout';

type Props = {
  text: string;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  onClick?: () => void;
};

const HomeQuickButton = ({ text, Icon, onClick }: Props) => (
  <S.QuickButtonContainer onClick={onClick}>
    <Icon />
    <S.QuickButtonText>{text}</S.QuickButtonText>
  </S.QuickButtonContainer>
);

export default HomeQuickButton;
