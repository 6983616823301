import { CONFIG_SWIPER } from '~/src/screens/Home/CatalogSwiper/layout';

const DEFAULT_CARD_NUMBER = 6;

export const getNbCardVisible = () => {
  if (window.matchMedia(CONFIG_SWIPER[4].mq).matches) return CONFIG_SWIPER[4].count;
  if (window.matchMedia(CONFIG_SWIPER[5].mq).matches) return CONFIG_SWIPER[5].count;
  if (window.matchMedia(CONFIG_SWIPER[6].mq).matches) return CONFIG_SWIPER[6].count;
  if (window.matchMedia(CONFIG_SWIPER[7].mq).matches) return CONFIG_SWIPER[7].count;
  if (window.matchMedia(CONFIG_SWIPER[8].mq).matches) return CONFIG_SWIPER[8].count;

  return DEFAULT_CARD_NUMBER;
};
