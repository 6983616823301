import React from 'react';

import { CardInfoWithoutPositionAndCountProperties } from '~/src/common/services/Tracker';
import { sanitize } from '~/src/common/utils/text';
import { useHome } from '~/src/queries/api-ecom/generated/api-ecom';
import HomeCategorySwiper from '~/src/screens/Home/HomeCategory/HomeCategorySwiper';

import { CATALOG_QUERY_PARAMS } from '../queries';
import { HomeCategory as HomeCategoryType } from '../types';

import * as S from './layout';

interface Props {
  homeCategory: HomeCategoryType;
  categoryAnalyticsProperties: CardInfoWithoutPositionAndCountProperties;
}

const HomeCategory = ({ homeCategory, categoryAnalyticsProperties }: Props) => {
  const { name, description, products, id } = homeCategory;

  const { refetch } = useHome(CATALOG_QUERY_PARAMS);

  const productsIds = products.map(p => p.id);

  const isDescriptionDefined = !!description;

  return (
    <div id={`${id}-sub`}>
      <S.TitleContainer>
        <S.Title>{name}</S.Title>
        {isDescriptionDefined ? <S.Description>{sanitize(description)}</S.Description> : null}
      </S.TitleContainer>
      <HomeCategorySwiper
        products={products}
        placeholders={productsIds}
        subcategoryName={name}
        homeCategory={homeCategory}
        onRefresh={refetch}
        categoryAnalyticsProperties={categoryAnalyticsProperties}
      />
    </div>
  );
};

export default React.memo(HomeCategory);
