import {
  Enum_Componenttextetexteaveccouleur_Color,
  GetMainLandingPageV1Query,
  GetMainLandingPageV2Query,
} from '~/src/queries/cms/generated/cms';

export type MainLandingPageInfos =
  | GetMainLandingPageV1Query['mainLandingPageV1']
  | GetMainLandingPageV2Query['mainLandingPageV2'];

type TextWithColorEnumType = Enum_Componenttextetexteaveccouleur_Color;

export const TextWithColorEnum = Enum_Componenttextetexteaveccouleur_Color;

export type TitleItemProps = {
  Content?: string | null;
  Color?: TextWithColorEnumType | null;
  __typename?: string;
} | null;
