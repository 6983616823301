import I18n from '~/src/common/services/I18n';
import { CATALOG_SIDE_PADDING_V2 } from '~/src/screens/App/CatalogLayout/layout';

import { StyledButton, ButtonStyledIcon } from './layout';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  direction: 'right' | 'left';
};

const ArrowButton = ({ direction, ...props }: Props) => (
  <StyledButton
    {...props}
    aria-label={I18n.t(`home.catalog-swiper.${direction}`)}
    direction={direction}
    style={
      direction === 'left'
        ? { left: -CATALOG_SIDE_PADDING_V2 }
        : { right: -CATALOG_SIDE_PADDING_V2 }
    }>
    <ButtonStyledIcon name={`Chevron-${direction}`} role="img" size={56} color="PRIMARY" />
  </StyledButton>
);

export default ArrowButton;
