import styled, { css } from 'styled-components';

import Link from '~/src/common/components/Link';
import { media } from '~/src/styles-constants';

const baseCss = css`
  aspect-ratio: 3/1;
  width: 100%;
  border-radius: 10px;
  padding: 0;
  ${media.S`
    width: 560px;
    min-width: 560px;
    height: 200px;
  `}
`;

const buttonCardStyle = css`
  ${baseCss}
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

export const ButtonCardContainer = styled.div`
  ${buttonCardStyle}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

export const LinkCard = styled(Link)`
  > img {
    ${baseCss}
  }
`;
