import { useMemo } from 'react';

import { CatalogItem } from '~/src/typings/products/types';

const useItems = (products?: CatalogItem[], placeholders?: string[]) =>
  useMemo(() => {
    return (placeholders ?? products?.map(p => p.id) ?? []).map(productId => {
      const product = products?.find(p => p.id === productId);
      return { product, productId };
    });
  }, [placeholders, products]);

export default useItems;
