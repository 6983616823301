import styled from 'styled-components';

import { P, H2 } from '~/src/common/components/Typography';

export const TitleContainer = styled.div`
  margin-bottom: 8px;
`;

export const Description = styled(P)`
  font-weight: 400;
  line-height: 24px;
  margin: 0;
`;

export const Title = styled(H2)`
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const EmptyText = styled.p`
  text-align: center;
  font-size: 12px;
  font-style: italic;
  padding-top: 40px;
`;
