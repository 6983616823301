/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

import Icon from '~/src/common/components/Icon';
import { getMediaQuery, transparentize } from '~/src/common/utils/style';
import {
  CATALOG_SIDE_PADDING,
  CATALOG_SIDE_PADDING_V2,
} from '~/src/screens/App/CatalogLayout/layout';
import { media } from '~/src/styles-constants';

// Item à droite de la liste permettant d'indiquer du contenu disponible à scroller
const MOBILE_ITEM_PREVIEW_WIDTH = 48;

const MOBILE_ITEM_GAP = 8;
export const DESKTOP_ITEM_GAP = 16;

export const ButtonStyledIcon = styled(Icon)`
  position: relative;
  z-index: 10;
  transition: opacity 0.3s;
  opacity: 0;
`;

export const StyledButton = styled.button<{ direction: 'left' | 'right' }>`
  display: none;

  position: absolute;
  top: 0;

  z-index: 10;

  height: 100%;
  width: ${CATALOG_SIDE_PADDING_V2}px;

  margin: 0;
  padding: 0;

  background-color: ${({ theme }) => theme.palette.common.TRANSPARENT};
  transition: background-color 0.3s;
  border: none;
  cursor: pointer;

  &,
  * {
    outline: none;
  }

  &:hover,
  &:active {
    border-color: inherit;
    box-shadow: none;
  }

  ${media.XL`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  `}

  & > ${ButtonStyledIcon} {
    ${({ direction }) => css`
      transform: translateX(${direction === 'left' ? '-8' : '8'}px);
    `};
  }
`;

export const MainContainer = styled.div`
  position: relative;

  &:hover {
    ${ButtonStyledIcon} {
      opacity: 1;
    }

    ${StyledButton} {
      background-color: ${({ theme }) => transparentize(theme.palette.common.WHITE, 0.88)};
    }
  }
`;

// La largeur des cartes a été définie via des media queries afin de pouvoir respecter les contraintes suivantes :
// - La position de la carte la plus à droite sur desktop est calée sur les marges de la page
// - Pour respecter la contrainte précédente, la largeur des cartes n'est pas fixe et est calculée par le navigateur
// - Un 1er rendu réalisé purement en CSS afin de ne pas avoir de CLS (Cumulative Layout Shift)

export const CONFIG_SWIPER = [
  { mq: getMediaQuery(0, 635), count: 2 },
  { mq: getMediaQuery(635, 934), count: 3 },
  { mq: getMediaQuery(934, 1140), count: 4 },
  { mq: getMediaQuery(1140, 1248), count: 5 },
  { mq: getMediaQuery(1248, 1430), count: 5 },
  { mq: getMediaQuery(1430, 1640), count: 6 },
  { mq: getMediaQuery(1640, 1850), count: 7 },
  { mq: getMediaQuery(1850, 2060), count: 8 },
  { mq: getMediaQuery(2060, null), count: 9 },
];

export const ScrollContainer = styled.div`
  position: relative;
  display: flex;

  overflow: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  padding-top: 8px;
  padding-bottom: 8px;

  &::-webkit-scrollbar {
    display: none;
  }

  --gap: ${MOBILE_ITEM_GAP}px;

  ${media.XL`
    --gap: ${DESKTOP_ITEM_GAP}px;
  `}

  gap: var(--gap);

  --preview-item: ${MOBILE_ITEM_PREVIEW_WIDTH}px;
  --nb-cards: ${CONFIG_SWIPER[5].count};

  @media ${CONFIG_SWIPER[0].mq} {
    --nb-cards: ${CONFIG_SWIPER[0].count};
  }

  @media ${CONFIG_SWIPER[1].mq} {
    --nb-cards: ${CONFIG_SWIPER[1].count};
  }

  @media ${CONFIG_SWIPER[2].mq} {
    --nb-cards: ${CONFIG_SWIPER[2].count};
  }

  @media ${CONFIG_SWIPER[3].mq} {
    --nb-cards: ${CONFIG_SWIPER[3].count};
  }

  @media ${CONFIG_SWIPER[4].mq} {
    --nb-cards: ${CONFIG_SWIPER[4].count};
  }

  @media ${CONFIG_SWIPER[5].mq} {
    --nb-cards: ${CONFIG_SWIPER[5].count};
  }

  @media ${CONFIG_SWIPER[6].mq} {
    --nb-cards: ${CONFIG_SWIPER[6].count};
  }

  @media ${CONFIG_SWIPER[7].mq} {
    --nb-cards: ${CONFIG_SWIPER[7].count};
  }

  @media ${CONFIG_SWIPER[8].mq} {
    --nb-cards: ${CONFIG_SWIPER[8].count};
  }

  --container-padding: ${CATALOG_SIDE_PADDING}px;

  ${media.M`
    --container-padding: ${CATALOG_SIDE_PADDING_V2}px;
  `}

  margin-left: calc(-1 * var(--container-padding));
  margin-right: calc(-1 * var(--container-padding));

  /* Contenu additionnel en fin de liste */
  &::before,
  &::after {
    content: '';
    display: block;
    height: 50px;
    flex: 0 0 auto;
    width: calc(var(--container-padding) - var(--gap));
  }

  && > * {
    flex: 0 0 auto;
    width: calc(((100vw - var(--container-padding)) - var(--preview-item) - var(--nb-cards) * var(--gap)) / var(--nb-cards));

    ${media.XL`
      width: calc(((100vw - 2 * var(--container-padding)) - var(--nb-cards) * var(--gap)) / var(--nb-cards));
      scroll-snap-align: start;
      scroll-margin: var(--container-padding);
    `}
  }
`;
