import styled from 'styled-components';

import Button, { Props as ButtonProps } from '~/src/common/components/Button';
import { media, zIndex } from '~/src/styles-constants';

type Props = Omit<ButtonProps, 'icon'> & {
  direction: 'right' | 'left';
  style?: Record<string, string>;
};

export const Chevron = styled(Button)`
  display: none;
  ${media.S`
    display: block;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    z-index: ${zIndex.marketingHeadersChevron};
    margin:0;
  `}
`;

const CarouselArrow = ({ direction, ...props }: Props) => (
  <Chevron {...props} variant="transparent" icon={`Chevron-${direction}`} />
);

export default CarouselArrow;
