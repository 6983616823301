import { useEffect, useState } from 'react';

const useScrollPosition = (scrollContainer: HTMLDivElement | null) => {
  const [scrollPosition, setScrollPosition] = useState<'start' | 'end' | 'middle'>('start');

  useEffect(() => {
    const onScroll = () => {
      if (scrollContainer) {
        // Largeur du contenu scrollable - largeur de la fenêtre de visualisation
        // Cela donne la position maximale du scroll
        // -1 pour éviter les erreurs d'arrondi
        const maxScrolledPosition =
          scrollContainer.scrollWidth - scrollContainer.getBoundingClientRect().width - 1;

        if (scrollContainer.scrollLeft >= maxScrolledPosition) {
          setScrollPosition('end');
        } else if (scrollContainer.scrollLeft === 0) {
          setScrollPosition('start');
        } else {
          setScrollPosition('middle');
        }
      }
    };

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', onScroll);
    }

    return () => {
      scrollContainer?.removeEventListener('scroll', onScroll);
    };
  }, [scrollContainer]);

  return {
    hasScrolled: scrollPosition !== 'start',
    hasFullyScrolled: scrollPosition === 'end',
  };
};

export default useScrollPosition;
