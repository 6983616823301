import { useEffect } from 'react';

import Tracker from '~/src/common/services/Tracker';

const useScrollAnalytics = (scrollContainer: HTMLDivElement | null, subcategoryName: string) => {
  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainer) {
        Tracker.sendEvent('scroll horizontal list', {
          'subcategory name': subcategoryName,
        });
      }
    };

    scrollContainer?.addEventListener('scroll', handleScroll, { once: true });

    return () => {
      scrollContainer?.removeEventListener('scroll', handleScroll);
    };
  }, [scrollContainer, subcategoryName]);
};

export default useScrollAnalytics;
