import { useEffect, useState } from 'react';

import { FindBusinessResponse } from '~/src/common/services/Trustpilot/types';
import { noop } from '~/src/common/utils/function';
import constants from '~/src/env-constants';

const trustPilotURL = `https://api.trustpilot.com/v1/business-units/find?name=${constants.TRUSTPILOT_BUSINESS_NAME}&apikey=${constants.TRUSTPILOT_PUBLIC_API_KEY}`;

const fetchBusinessInformations = async () => {
  const request = await fetch(trustPilotURL);
  return request.json<FindBusinessResponse>();
};

const useGetReviews = () => {
  const [data, setData] = useState<FindBusinessResponse>();

  useEffect(() => {
    fetchBusinessInformations().then(setData).catch(noop);
  }, []);

  return { data };
};
export default useGetReviews;
