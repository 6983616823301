import React, { useRef } from 'react';

import ArrowButton from '~/src/screens/Home/CatalogSwiper/ArrowButton';
import useSwiperScroll from '~/src/screens/Home/CatalogSwiper/use-swiper-scroll';

import * as S from './layout';

interface Props {
  children: React.ReactNode;
  subcategoryName: string;
}

const CatalogSwiper = ({ children, subcategoryName }: Props) => {
  const mainContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const nbItems = React.Children.count(children);

  const { isScrollable, hasFullyScrolled, hasScrolled, handleScroll } = useSwiperScroll({
    nbItems,
    subcategoryName,
    mainContainer: mainContainerRef.current,
    scrollContainer: scrollContainerRef.current,
  });

  return (
    <S.MainContainer ref={mainContainerRef}>
      {isScrollable && hasScrolled ? (
        <ArrowButton
          direction="left"
          onClick={() => {
            handleScroll('left');
          }}
        />
      ) : null}
      <S.ScrollContainer ref={scrollContainerRef}>{children}</S.ScrollContainer>
      {isScrollable && !hasFullyScrolled ? (
        <ArrowButton
          direction="right"
          onClick={() => {
            handleScroll('right');
          }}
        />
      ) : null}
    </S.MainContainer>
  );
};

export default CatalogSwiper;
