import { useCallback, useRef, useState } from 'react';

import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useNiceModal } from '~/src/common/services/ModalsManager';
import CarouselArrow from '~/src/screens/App/Header/CarouselArrow';
import HeaderCard from '~/src/screens/App/Header/HeaderCard';
import { ParsedMarketingHeader } from '~/src/screens/App/Header/types';
import { breakpoints } from '~/src/styles-constants';

import * as S from './layout';

const appendDots = (dots: React.ReactNode) => <S.SlickDotContainer>{dots}</S.SlickDotContainer>;
const responsive: Settings['responsive'] = [
  {
    breakpoint: breakpoints.S,
    settings: {
      variableWidth: false,
      dots: true,
      arrows: false,
      slidesToShow: 1,
      autoplay: true,
    },
  },
];

const MarketingHeaders = ({ marketingHeaders }: { marketingHeaders: ParsedMarketingHeader[] }) => {
  const sliderRef = useRef<Slider>(null);
  const [leftArrowVisibled, setShowLeftArrow] = useState(false);
  const { show } = useNiceModal('marketing-modal');

  const handleClick = useCallback((infoSlug: string) => show({ infoSlug }), [show]);
  const afterChange = useCallback(
    (currentSlide: number) => setShowLeftArrow(currentSlide !== 0),
    [],
  );

  return (
    <S.CarouselContainer>
      <S.SliderNav>
        <Slider
          infinite
          variableWidth
          arrows={false}
          responsive={responsive}
          appendDots={appendDots}
          afterChange={afterChange}
          ref={sliderRef}>
          {marketingHeaders.map(header => (
            // eslint-disable-next-line @typescript-eslint/no-misused-promises  -- auto-ignored when updating eslint
            <HeaderCard key={header.id} header={header} onMarketingModalOpen={handleClick} />
          ))}
        </Slider>
        <CarouselArrow
          direction="right"
          onClick={() => sliderRef.current?.slickNext()}
          style={{
            top: 'calc(50% + 12px)',
            right: '-50px',
          }}
        />
        {leftArrowVisibled ? (
          <CarouselArrow
            direction="left"
            onClick={() => sliderRef.current?.slickPrev()}
            style={{
              top: 'calc(50% + 12px)',
              left: '-38px',
            }}
          />
        ) : null}
      </S.SliderNav>
    </S.CarouselContainer>
  );
};

export default MarketingHeaders;
