import React from 'react';

import RichText from '~/src/common/components/CMS/RichText';
import { ReadMore } from '~/src/common/components/ReadMore/ReadMore';
import RefreshCategory from '~/src/common/components/RefreshCategory';
import { H1 } from '~/src/common/components/Typography';
import { useAuthenticated } from '~/src/common/hooks/user/use-authenticated';
import { useFeatureFlag } from '~/src/common/services/FeatureFlag';
import I18n from '~/src/common/services/I18n/I18n';
import { GetCategories200ItemsItem } from '~/src/queries/api-ecom/generated/api-ecom.schemas';
import useMarketingHeaders from '~/src/screens/Home/MarketingHeaders/use-marketing-headers';

import CategoryContentSkeleton from '../CatalogContent/ListProductsScreen/CategoryPage/CategoryContentSkeleton';

import HomeCategory from './HomeCategory';
import HomeQuickButtonsSection from './HomeQuickButtonsSection';
import * as S from './layout';
import MarketingHeaders from './MarketingHeaders';
import { HomeCategory as HomeCategoryType } from './types';

interface Props {
  homeCategories?: HomeCategoryType[] | null;
  userFirstname?: string;
  numberOfCategories?: number;
  isError: boolean;
  onRefresh: () => void;
  homeCatData: GetCategories200ItemsItem | undefined;
}

const HomeScreenContent = ({
  homeCategories,
  userFirstname,
  numberOfCategories,
  isError,
  onRefresh,
  homeCatData,
}: Props) => {
  const isAuthenticated = useAuthenticated();
  const isMyProductsEnabled = useFeatureFlag('mes-produits');
  const showQuickAccessButtons = isAuthenticated && !isMyProductsEnabled;

  const titleH1 = homeCatData?.seo?.h1 || I18n.t('home.category-name');
  const footerDescription: string = (homeCatData?.footerContent as string) || '';

  const marketingHeaders = useMarketingHeaders();

  if (isError) {
    return <RefreshCategory onButtonClick={onRefresh} />;
  }

  if (homeCategories == null) {
    return <CategoryContentSkeleton isHome />;
  }

  return (
    <div>
      <S.HomeTitleContainer>
        <S.Waves />
        <H1>{titleH1}</H1>
      </S.HomeTitleContainer>

      {homeCategories[0] ? (
        <HomeCategory
          homeCategory={homeCategories[0]}
          key={homeCategories[0].id}
          categoryAnalyticsProperties={{
            'subcategory position': 1,
            'category position': 0,
            'number of categories': numberOfCategories || 0,
            'number of subcategories': homeCategories.length,
          }}
        />
      ) : null}

      {marketingHeaders.length > 0 ? (
        <MarketingHeaders marketingHeaders={marketingHeaders} />
      ) : null}

      {showQuickAccessButtons ? <HomeQuickButtonsSection userFirstname={userFirstname} /> : null}

      {homeCategories.map((homeCategory, index) =>
        index === 0 ? null : (
          <HomeCategory
            homeCategory={homeCategory}
            key={homeCategory.id}
            categoryAnalyticsProperties={{
              'subcategory position': index + 1,
              'category position': 0,
              'number of categories': numberOfCategories || 0,
              'number of subcategories': homeCategories.length,
            }}
          />
        ),
      )}
      {footerDescription !== '' ? (
        <S.FooterContentContainer>
          <ReadMore path="/">
            <RichText html={footerDescription} />
          </ReadMore>
        </S.FooterContentContainer>
      ) : null}
    </div>
  );
};

export default HomeScreenContent;
